body {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.img-grayscale {
  filter: grayscale(1);
  width: auto;
}

.caption-item {
  color: royalblue;
}

@media (min-width: 1250px) {
  .caption {
    position: absolute;
    top: 65%;
  }
}

@media (max-width: 1249px) {
  .caption {
    position: absolute;
    top: 35%;
  }
  .img-grayscale {
    margin-top: 56px;
  }
}

.img-tulsa {
  height: 25vh;
}

#about {
  line-height: 2.0rem;
}

.spacer {
  margin: 2em;
}

.ed-location {
  margin-bottom: 0px;
}

.cert-location {
  margin-bottom: 0px;
}

.pro-location {
  margin-bottom: 0px;
}

.certs-container {
  background-image: linear-gradient(#f8f9fa, #343a40)
}

.hidden {
  display: none;
}

.btn-link {
  color: #212529 !important;
}